<template>
  <div class="indexs">
    <div class="navtit">
      <div class="navtititem" @mouseenter="enter(1)" @mouseleave="quit">
        <p class="navtititemtit">
          {{ $t("language.hostentry") }}
          <!-- 主办入口 -->
        </p>
        <p class="navtititemexp">
          <!-- {{ $t("language.inbookingthevenue") }} -->
          <!-- 作为主办方，我有意向预定场地 -->
        </p>
        <p class="iconfont">
          <img style="margin-top: 30px;" src="../assets/image/入口箭头.png" alt="" />
        </p>
        <div class="shows">
          <p @click="gopage('展会排期', 'TradeShowTrends')">展馆排期</p>
          <p @click="gopage('展览场地', 'exhibition')" style="margin-right: 20px">场馆技术参数</p>
          <p @click="gopage('场地动线图', 'datadownload')">入场动线</p>
<!--          <p @click="gopage('搭建服务', 'Exhibitionprocess')">办展流程</p>-->
          <p @click="gopage('管理规定', 'datadownload')">管理规定</p>
          <p @click="gopage('餐饮服务', 'ExhibitionServices')">餐饮服务</p>
          <p @click="gopage('搭建服务', 'ExhibitionServices')">搭建服务</p>
          <p @click="gopage('展具租赁', 'ExhibitionServices')">展具租赁</p>
          <p @click="gopage('广告服务', 'ExhibitionServices')">广告服务</p>
          <p @click="gopage('宣传手册', 'datadownload')">宣传手册</p>
          <p @click="gopage('交通指南', 'traffic')">交通指南</p>
          <p @click="gopage('周边酒店', 'traffic')">周边酒店</p>
        </div>
      </div>
      <div class="navtititem" @mouseenter="enter(2)" @mouseleave="quite">
        <div class="shows">
          <p
            @click="gopage('展览场地', 'exhibition')"
            style="margin-right: 20px"
          >
            场馆技术参数
          </p>
          <p @click="gopage('交通指南', 'traffic')">交通指南</p>
          <p @click="gopage('场地动线图', 'datadownload')">货运动线</p>
          <p @click="gopage('展具租赁', 'ExhibitionServices')">展具租赁</p>
          <p @click="gopage('搭建服务', 'ExhibitionServices')">搭建服务</p>
          <p @click="gopage('管理规定', 'datadownload')">管理规定</p>
          <p @click="gopage('周边酒店', 'traffic')">周边酒店</p>
          <p @click="gopage('餐饮服务', 'ExhibitionServices')">餐饮服务</p>
        </div>
        <p class="navtititemtit">{{ $t("language.ExhibitorEntrance") }}</p>
        <p class="navtititemexp">
          <!-- {{ $t("language.ExhibitorEntranceText") }} -->
        </p>
        <p class="iconfont">
          <img style="margin-top: 30px;" src="../assets/image/入口箭头.png" alt="" />
        </p>
      </div>
      <div class="navtititem" @mouseenter="enter(3)" @mouseleave="quites">
        <div class="shows">
          <p @click="gopage('展览场地', 'exhibition')">展会日程</p>
          <p @click="gopage('交通指南', 'traffic')">交通指南</p>
          <p @click="gopage('周边酒店', 'traffic')">周边酒店</p>
          <p @click="gopage('旅游景点', 'traffic')">旅游景点</p>
        </div>
        <p class="navtititemtit">{{ $t("language.AudienceEntrance") }}</p>
        <p class="navtititemexp">
          <!-- {{ $t("language.AudienceEntranceText") }} -->
        </p>
        <p class="iconfont">
          <img style="margin-top: 30px;" src="../assets/image/入口箭头.png" alt="" />
        </p>
      </div>
    </div>
    <div class="particulars">
      <p class="logos">NEWS</p>
      <div class="particularsleft">
        <p class="title">
          <span>{{ $t("language.NewInformation") }}</span>
          <span style="cursor: pointer" @click="goNews()"
            >MORE<img
              style="width: 8px; height: 12px"
              src="../assets/image/banner默认按钮.png"
              alt=""
          /></span>
        </p>
        <div class="particularscontent">
          <p class="leftlog">
            <span>Hot</span>
            <span></span>
            <span>{{ date.date }}</span>
          </p>
          <p class="rightlog" @click="lookparticulars(date.id)">
            <span class="righttitle">{{ date.title }}</span>
            <!-- 主办单位:烟台八角湾国际会展中心 -->
            <!-- <span>{{ $t("language.Organizer") }}</span> -->
          </p>
        </div>
        <ul class="lists">
          <li
            v-for="(item, index) in newslist"
            :key="index"
            @click="lookparticulars(item.id)"
          >
            <span>. {{ item.title }} </span
            ><span style="color: #666">{{ item.date }}</span>
          </li>
        </ul>
      </div>
      <div class="particularscenter">
        <img
          style="width: 100%; height: 215px"
          :src="dataa.introduceImg"
          alt=""
        />
        <p class="titles">{{ dataa.title }}</p>
        <p class="conts" v-html="dataa.introduce"></p>
        <p class="bots">
          <span>{{ dataa.date }}</span>
          <!-- 浏览更多 -->
          <span class="BrowseMore" @click="lookparticulars(dataa.id)">{{
            $t("language.BrowseMore")
          }}</span>
        </p>
      </div>
      <div class="particularsright">
        <img
          style="width: 100%; height: 215px"
          :src="datab.introduceImg"
          alt=""
        />
        <p class="titles">{{ datab.title }}</p>
        <p class="conts" v-html="datab.introduce"></p>
        <p class="bots">
          <span>{{ datab.date }}</span>
          <!-- 浏览更多 -->
          <span class="BrowseMores" @click="lookparticulars(datab.id)">{{
            $t("language.BrowseMore")
          }}</span>
        </p>
      </div>
    </div>
    <div class="botnav">
      <div class="navcco">
        <div class="navcontents">
          <div>
            <!-- 关于我们 -->
            <p
              v-html="$t('language.abloutwe')"
              @click="gopage('鲜美烟台', 'deliciousyt')"
            ></p>
            <p class="g">|</p>
            <!-- 展馆指南 -->
            <p
              v-html="$t('language.PavilionGuide')"
              @click="gopage('展馆概况', 'exhibition')"
            ></p>
            <p class="g">|</p>
            <!-- 展会动态 -->
            <p
              v-html="$t('language.ExhibitionTrends')"
              @click="gopage('新闻动态', 'TradeShowTrends')"
            ></p>
            <p class="g">|</p>
            <!-- 展会服务 -->
            <p
              v-html="$t('language.ExhibitionServices')"
              @click="gopage('餐饮服务', 'ExhibitionServices')"
            ></p>
            <p class="g">|</p>
            <!-- 资料下载 -->
            <p
              v-html="$t('language.DataDownload')"
              @click="gopage('展馆平面图', 'datadownload')"
            ></p>
            <p class="g">|</p>
            <!-- 周边服务 -->
            <p
              v-html="$t('language.PeripheralServices')"
              @click="gopage('交通指南', 'traffic')"
            ></p>
          </div>
          <!-- 友情链接：中国会展经济研究会 中国展览馆协会 烟台业达成式发展集团有限公司 -->
          <p class="explain">友情链接：<a href="http://ytydcf.com" target="black" style="color:#ffffff">烟台业达城市发展集团有限公司</a></p>
          <!-- <p class="explain">{{ $t("language.Link") }}</p> -->
          <!-- 烟台业达国际会展有限公司 烟台业达文旅有限公司 -->
          <!-- <p class="explain">{{ $t("language.Linkx") }}</p> -->
          <p class="explain" v-html="$t('language.shandongyantai')"></p>
        </div>
        <div class="relation">
          <!-- 烟台八角国际会展中心 -->
          <!-- <p>{{ $t("language.ExhibitionCore") }}</p> -->
          <p>{{ $t("language.Telephone") }}  <span style="margin:0 5px 0 3px;">:</span>  0535-6959157</p>
          <!-- 地址: 烟台经济技术开发区八角湾 -->
          <!-- <p v-html="$t('language.shandongyantai')"></p> -->
          <!-- <p>投诉电话 <span style="margin:0 5px 0 3px;">:</span>  0535-6939167</p> -->
          <p>{{$t("language.Mailbox")}}  <span style="margin:0 5px 0 3px;">:</span> ytbjw@ybicec.com</p>
        </div>
        <div class="relationequipment">
          <!-- 关注 -->
          <p>
            <img src="../assets/image/底部导航-微博.png" alt="" /><img
              src="../assets/image/底部导航-微信.png"
              alt=""
            />
          </p>
        </div>
        <div class="relationcode">
          <img src="../assets/image/二维码.png" alt=""  style="width:62px;height:62px"/>
          <!-- 微信公众号 -->
          <p>{{ $t("language.WeChatOfficialAccount") }}</p>
        </div>
      </div>
    </div>
    <div class="indexbottom">
      {{ $t("language.Record") }}
      <img src="../assets/image/beian.png" alt=""  style="width:12px;height:12px;"/>
      <a style="color: #ffffff;margin-left: 4px;" href="https://beian.mps.gov.cn/#/query/websearch?code=37069302889046" rel="noreferrer" target="_blank">鲁公网安备37069302889046</a> 
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      shows: false,
      showes: false,
      newslist: [],
      date: [],
      dataa: [],
      datab: [],
    };
  },
  created() {
    this.getlist();
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
       handleScroll() {
      // 监听鼠标滚动
      // 页面滚动距顶部距离
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      var scroll = scrollTop - this.i;
      this.i = scrollTop;
      if (scroll > 0) {
        console.log("1");
        // 鼠标上滚  执行的的方法
        this.$router.push({ path: "index", query: { id: 1 } })
      } else {
        console.log("2");
        // 鼠标下滚  执行的的方法
      }
    },
    getlist() {
      let data = {
        pageNum: 1,
        pageSize: 10,
        newsType:'1'
      };
      this.$api.newsandtrends(data, (res) => {
        if (res.data.code) {
          let newslist = res.data.rows.map((item) => {
            item.date = item.releaseTime.substring(0, 11);
            item.newsContent = `${item.newsContent.substring(0, 100)}...`;
            return item;
          });
          this.dataa = newslist.splice(0, 1)[0];
          this.datab = newslist.splice(0, 1)[0];
          this.date = newslist.splice(0, 1)[0];
          this.newslist = newslist.splice(0, 5);
        }
      });
    },
    goNews() {
      this.$router.replace({
        path: "/TradeShowTrends",
        query: { path: `新闻动态` },
      });
    },
    lookparticulars(id) {
      this.$router.push({ path: "lookparticulars", query: { id: id } });
    },
    gopage(title, val) {
      this.$router.push({ path: val, query: { path: title } });
    },
    quit() {
      this.show = false;
    },
    quite() {
      this.shows = false;
    },
    quites() {
      this.showes = false;
    },
    enter(val) {
      if (val == 1) {
        this.show = true;
        this.shows = false;
        this.showes = false;
      } else if (val == 2) {
        this.show = false;
        this.showes = false;
        this.shows = true;
      } else if (val == 3) {
        this.shows = false;
        this.show = false;
        this.showes = true;
      }
    },
  },
};
</script>

<style scoped>
@media screen and (max-height: 969px) {
    .navtititem{
      margin-top: 20px;
    }
    .navtit{
      height: 294px;
    }
    .particulars{
      margin: 0 auto 20px;
    }
    .botnav{
      height: 124px;
    }
    .navcco{
      padding-top: 12px;
      box-sizing: border-box;
    }
}
@media screen and (min-height: 969px) {
    .navtititem{
      margin-top: 50px;
    }
    .navtit{
      height: 355px;
    }
    .particulars{
      margin: 0 auto 57px;
    }
    .botnav{
      height: 130px;
    }
    .navcco{
      box-sizing: border-box;
      padding-top: 20px;
    }
}
.indexs {
  width: 100%;
  height: 100%;
}
.relationcode p {
  font-size: 12px;
  color: #fff;
}
.relationcode {
  width: 77px;
  float: right;
  text-align: center;
  margin-top: 10px;
}
.relationequipment p:nth-of-type(1) img {
  margin-right: 30px;
}
.relationequipment p:nth-of-type(1) img:nth-last-of-type(1) {
  margin-right: 0;
}
.relationequipment {
  width: 115px;
  height: 60px;
  float: left;
  margin-right: 150px;
  margin-top: 15px;
}
.relation p:nth-of-type(1) {
  font-size: 14px;
  margin-bottom: 15px;
}
.relation p:nth-of-type(2) {
  font-size: 14px;
  margin-bottom: 13px;
}
.relation p:nth-of-type(3) {
  font-size: 14px;
}
.relation {
  width: 310px;
  height: 90px;
  float: left;
  color: #fff;
  margin-right: 70px;
  margin-top: 7px;
}
.explain:nth-of-type(1) {
  margin-top: 10px;
}
.explain {
  line-height: 30px;
}
.navcontents p {
  font-size: 15px;
  color: #fff;
  float: left;
  cursor: pointer;
}
.navcontents .g {
  font-size: 15px;
  color: #545454;
  margin: 0 15px;
}
.navcontents {
  width: 565px;
  height: 90px;
  margin-right: 113px;
  float: left;
  margin-top: 10px;
}
.navcco {
  width: 1400px;
  margin: 0 auto;
  height: 100%;
  box-sizing: border-box;
}
.navtit {
  width: 1400px;
  margin: 0 auto;
}
.index {
  width: 100%;
  height: 100%;
}
.index::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.navtititem {
  width: 450px;
  height: 240px;
  overflow: hidden;
  float: left;
  margin-right: 25px;
  position: relative;
}
.navtititem:nth-last-of-type(1) {
  padding: 72px 0 85px 30px;
  margin-right: 0;
  background: url("../assets/image/lALPDhmOwjW3zgPM8M0Bvg_446_240.png");
  box-sizing: border-box;
  font-size: 15px;
  color: #fff;
}
.navtititem .navtititemtit {
  font-size: 32px;
  margin-bottom: 15px;
}
.navtititem:nth-last-of-type(3) {
  padding: 72px 0 85px 30px;
  box-sizing: border-box;
  color: #fff;
  background: url("../assets/image/lALPDiCpv39EzgTM8M0Bvg_446_240.png");
  position: relative;
  font-size: 15px;
}
.navtititem:nth-last-of-type(2) {
  position: relative;
  padding: 72px 0 85px 30px;
  box-sizing: border-box;
  color: #fff;
  font-size: 15px;
  background: url("../assets/image/lALPDhmOwjW0QSjM8M0Bvg_446_240.png");
}
.shows {
  position: absolute;
  left: 0;
  top: 240px;
  width: 100%;
  height: 100%;
  padding: 45px 0 0 62px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.6s;
}
.navtititem:hover .shows{
  transform: translateY(-240px);
}
.shows p {
  float: left;
  margin-right: 50px;
  margin-bottom: 24px;
  cursor: pointer;
}
.shows p:nth-last-of-type(1) {
  float: left;
  margin-right: 0;
}
.particulars {
  width: 1400px;
  height: 400px;
  position: relative;
}
.logos {
  font-size: 31px;
  color: #e6e6e6;
  position: absolute;
  left: 0;
  top: 0;
}
.particularsleft {
  width: 545px;
  height: 365px;
  float: left;
  margin-top: 20px;
}
.title {
  width: 100%;
}
.title span:nth-of-type(1) {
  font-size: 30px;
  position: absolute;
  left: 0px;
  top: 20px;
  z-index: 3;
}
.title span:nth-of-type(2) {
  float: right;
  margin-top: 13px;
  color: #4197d0;
}
.title span:nth-of-type(2) img {
  margin-left: 13px;
  font-size: 15px;
  color: #4197d0;
}
.leftlog {
  width: 99px;
  height: 97px;
  background: #1881c5;
  text-align: center;
  padding-top: 20px;
  box-sizing: border-box;
  float: left;
}
.particularscontent {
  width: 100%;
  margin-top: 78px;
  height: auto;
}
.leftlog span:nth-of-type(1) {
  font-size: 22px;
  display: block;
  color: #fff;
}
.leftlog span:nth-of-type(2) {
  width: 22px;
  height: 9px;
  border-bottom: 1px solid #fff;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}
.leftlog span:nth-of-type(3) {
  font-size: 15px;
  color: #fff;
}
.rightlog {
  float: right;
  color: #666;
  cursor: pointer;
}
.rightlog:hover .righttitle {
  color: #1881c5 !important;
}
.rightlog span:nth-of-type(1) {
  display: block;
  width: 416px;
  font-size: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 15px 0 20px;
  white-space: nowrap;
  color: #000;
}
.lists {
  width: 100%;
  height: auto;
  clear: both;
  list-style: none;
}
.lists li {
  clear: both;
  line-height: 43px;
  cursor: pointer;
}
.lists li :hover {
  color: #1881c5;
}
.lists li span:nth-of-type(1) {
  float: left;
  width: 390px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lists li span:nth-of-type(2) {
  float: right;
}
.particularscenter {
  float: left;
  margin-left: 60px;
  width: 383px;
  height: 100%;
}
.BrowseMore {
  cursor: pointer;
}
.particularscenter :hover .BrowseMore {
  color: #1881c5 !important;
}
.BrowseMores {
  cursor: pointer;
}
.particularsright :hover .BrowseMores {
  color: #1881c5 !important;
}
.particularsright {
  float: left;
  margin-left: 29px;
  width: 383px;
  height: 100%;
}
.titles {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 375px;
  margin-left: 5px;
  font-size: 20px;
  margin-top: 25px;
}
.conts {
  height: 38px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 14px;
  width: 368px;
  margin: 33px auto 37px;
  color: #666;
}
.bots {
  width: 368px;
  font-size: 12px;
  color: #666;
}
.bots span:nth-of-type(1) {
  float: left;
  padding-left: 7px;
  box-sizing: border-box;
}
.bots span:nth-of-type(2) {
  float: right;
}
.botnav {
  width: 100%;
  background: #333333;
}
.indexbottom {
  width: 100%;
  height: 27px;
  background: #1a1a1a;
  text-align: center;
  line-height: 27px;
  font-size: 12px;
  color: #fff;
}
</style>
