<template>
  <div class="index">
    <swiper id="swiperBox" v-bind:options="swiperOption" ref="mySwiper">
      <swiper-slide class="swiper-slide">
        <div class="page" style="width:100%;height:100%;position: relative;">
          <Carousel/>
          <img  class="swiper-button-next imgs" slot="button-next" src="../../assets/image/下滑.png" alt="" />
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slides" style="overflow: auto;">
        <div class="page" style="height:100%;position: relative;">
          <Index/>
          <div class="noticeNotice">
            
            <div ref="contentIcon" class="contentIcon" @mouseenter="DetailsOpen" @mouseleave="DetailsOut" @click.self="gonoticeNotice" >
              <div class="AnnouncementDetails" ref="AnnouncementDetails" @click.stop="gonoticeNoticedetails(detailsId)"> 
                <p class="AnnouncementDetailsText">{{detailsTitle}}</p>
              </div>
            </div>
            <p class="icon" ref="icon" @click="noticeNoticeOpen">
              <img src="../../assets/image/折叠.png" alt=""/>
            </p>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
  
<script>
import Index from '../../components/indexs.vue'
import Carousel from "../../components/pc-carousel.vue";
export default {
  data() {
    return {
      detailsId:'',
      detailsTitle:'',
      list: [], //轮换列表
      swiperOption: {
         navigation: {
                    nextEl: '.swiper-button-next',
                },
        notNextTick: true, //notNextTick是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true
        direction: "vertical", //水平方向移动
        grabCursor: false, //鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，开启这个设定会在Wrapper上添加等于slides相加的宽或高，在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: true, //自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。可以设置为数字（可为小数，小数不可loop），或者 'auto'则自动根据slides的宽度来设定数量。loop模式下如果设置为'auto'还需要设置另外一个参数loopedSlides。
        mousewheel: true, //开启鼠标滚轮控制Swiper切换。可设置鼠标选项，默认值false
        mousewheelControl: true, //同上
        speed:1500,
        height: window.innerHeight, // 高度设置，占满设备高度
        resistanceRatio: 0, //抵抗率。边缘抵抗力的大小比例。值越小抵抗越大越难将slide拖离边缘，0时完全无法拖离。本业务需要
        observeParents: true, //将observe应用于Swiper的父元素。当Swiper的父元素变化时，例如window.resize，Swiper更新
        on: {
          slideChange: () => {
            let swiper = this.$refs.mySwiper.swiper;
            if (swiper.activeIndex === this.list.length - 1) {
              let newList = [];
              let listLength = this.list.length;
              for (let i = 0; i < 10; i++) {
                newList.push(listLength + i);
              }
              this.list = this.list.concat(newList);
            }
          },
        },
      },
    };
  },
  methods: {
     next:function(){
          if(this.imgindex==this.arr.length-1){
              this.imgindex=0;
          }else{
              this.imgindex++;
          }
      },
      gonoticeNoticedetails(id){
        this.$router.push({path:'lookparticularsNav',query:{id:id,path:'通知公告'}})
      },
      noticeNoticeOpen(){
        if (this.$refs.contentIcon.style.width == "0px") {
          this.$refs.contentIcon.style.width = "148px"
          this.$refs.icon.style.width = "148px"
        }else{
          this.$refs.contentIcon.style.width = "0px"
          this.$refs.icon.style.width = "30px"
        }
      },
      gonoticeNotice(){
        this.$router.replace({
          path: "/TradeShowTrends",
          query: { path: `通知公告` },
        });
      },
      DetailsOpen(){
        this.$refs.AnnouncementDetails.style.display = "block"
      },
      DetailsOut(){
        this.$refs.AnnouncementDetails.style.display = "none"
      }
  },
  created() {
    this.$api.newTzggFirst(null,(res) => {
        console.log(res.data)
        if (res.data.code == 200) {
          this.detailsTitle = res.data.data.title
          this.detailsId = res.data.data.id 
        }
      })
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  mounted() {
  },
  components: {
    Carousel,
    Index
  },
};
</script>
  
<style scoped>
*{
  padding:0;
}
.swiper-slides{
  width: 100%;
  height: 100%;
  overflow: auto;
}
.imgs {
  position: absolute;
  /* animation: example 3s; */
  width: 27px !important;
  height: 57px !important;
  bottom: 0 !important;
  left: 0;
  right: 0;
  margin: auto;
  background: none;
  top: calc(100% - 137px) !important;
  z-index: 10000 !important;
  }
  .noticeNotice{
    width: 148px;
    height: 175px;
    position: absolute;
    right: 20px;
    top: 300px;
  }
  .contentIcon{
    position: absolute;
    right: 0;
    top: 0;
    width: 148px;
    height: 148px;
    background-image: url("../../assets/image/编组.png");
    cursor: pointer;
    transition: .3s;
  }
  .AnnouncementDetails{
    width: 317px;
    height: 149px;
    display: none;
    box-sizing: border-box;
    padding: 40px 20px 40px 20px;
    background-image:url("../../assets/image/abc0e0d71333bd8fff30fafe211a9c6.png");
    position: absolute;
    left: -318px;
    top: 0;
  }
  .icon{
    position: absolute;
    right: 0;
    bottom: 0;
    background: #409EFF;
    width: 148px;
    height: 25px;
    text-align: center;
    cursor: pointer;
    transition: .3s;
  }
  .AnnouncementDetailsText{
    font-size: 23px;
    color: #fff;
    overflow: hidden; 
    text-overflow: ellipsis; 
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
  }
</style>
